
































































































.practical-train {
    box-sizing: border-box;
    height: 100%;
    padding: 20px 20px 0;
}
